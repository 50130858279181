.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
}

.parallax {
  padding: 2%;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1.5vmin);
  color: white;
  line-height: normal;
}

.container {
  margin-left:3%;
  margin-right:3%;
}

.App-navbar {
  padding: 2%;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1.5vmin);
  line-height: normal;
}

.App-header {
  padding: 2%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1.5vmin);
  color: white;
  line-height: normal;
}

.App-whatis {
  padding: 1%;
  min-height: 70vh;
  background: #FFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: black;
  line-height: normal;
}


.App-whatdoes {
  padding: 1%;
   background: #FFFF;
   min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: black;
  line-height: normal;
}

.App-image {
  height: 50vmin;
}



.App-whatcan {
  padding: 1%;
  background: #FFFF;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: black;
  line-height: normal;
}

.App-contact {
  padding: 1%;
  background: #FFFF;
  min-height: 70vh;
   margin-bottom: -5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  line-height: normal;
}



.App-footer p{
  background: #FFFF;
   margin-bottom: -5vh;
  text-align: center;
  line-height: 16px;
  font-size: 15px;  
  font-weight: 300;
  letter-spacing: .3px;
  color: black;
 
}


.App-link {
  color: #61dafb;
}

.button {
  margin: 10px;
  
}


